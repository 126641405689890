<template>
  <div id="app">
    <NavigationBar />
    <!-- URLに対応したコンポーネントを表示 -->
    <router-view></router-view>
  </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'

export default {
  name: 'App',
  components: {
    NavigationBar,
  }
}
</script>

<style>
#app {
  height: 100vh;
}

button.transparent {
  margin: 5px;
  background: transparent;
  border: none;
}

input.transparent {
  width: 100%;
  border: none;
}

input.transparent:focus {
  outline: none;
  -webkit-box-shadow: note;
  box-shadow: none;
}
</style>
